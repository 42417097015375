import React from "react"
import SEO from "../components/seo"

import Header from "../components/header"
import Hero from "../components/hero"

import { H1, H2, P } from "../components/text"
import Section from "../components/section"
import Footer from "../components/footer"

import imageMagasin from "../images/magasin.jpg"
import imageVins from "../images/vins.jpg"
import imageCharcuterie from "../images/charcuterie.jpg"
import imageFromages from "../images/fromages.jpg"

// Galerie
import imageBiscuits from "../images/biscuits.jpg"
import imageFromages2 from "../images/fromages-2.jpg"
import imageLimoncello from "../images/limoncello.jpg"
import imageRayonFromages from "../images/rayon-fromages.jpg"
import imagerayonHuiles from "../images/rayon-huiles.jpg"
import imageRayonVins from "../images/rayon-vins.jpg"
import imageRayon from "../images/rayon.jpg"
import imageFromage from "../images/fromage.jpg"

const Page = () =>
  <div>
    <SEO title="Home" />
    <Header/>

    <Hero title="Notre magasin" subtitle="Trouvez tous nos produits aux saveurs incontournables d’Italie dans notre magasin au coeur de Gembloux" image={imageMagasin}/>

    <Section className="container" style={{textAlign: "center", marginTop: "60px"}}>
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <P style={{fontSize: "24px"}}>Vous êtes à la recherche d’une épicerie fine italienne proposant des produits de qualité et un service de plats à emporter ?</P>
          <P style={{fontSize: "24px"}}>Raineri Gembloux comble toutes vos envies !</P>
        </div>
      </div>
    </Section>

    <Section className="container">
      <div className="row">
        <div className="col-md-6 order-md-1 order-2">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2534.1364541115036!2d4.692615215735694!3d50.56882717949181!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c18269573c8a99%3A0xbf5e999805aa9d77!2sRaineri!5e0!3m2!1sfr!2sbe!4v1579638179216!5m2!1sfr!2sbe" width="100%" height="450" frameBorder="0" style={{border: 0}} allowFullScreen=""></iframe>
        </div>
        <div className="col-md-6 text-center order-md-2 order-1">
          <H2>Adresse et horaire</H2>
          {/* <div style={{backgroundColor: "rgb(204, 29, 29)", marginBottom: "20px", color: "white", padding: "10px", fontSize: "20px"}}>
            Nous serons exceptionnellement ouvert le lundi 21 décembre et lundi 28 décembre de 9 à 18h.
            <br/><br/>
            <strong>La magasin sera fermé le samedi 2 janvier.</strong>
          </div> */}
          <P style={{fontSize: "22px"}}>
            <a href="https://goo.gl/maps/1Q9oNFVJYNurCvSR6" target="_blank">Rue de la Sucrerie 4A, 5030 Gembloux</a>
          </P>
          <P style={{fontSize: "22px"}}>Du mardi au jeudi de 9h à 18h</P>
          <P style={{fontSize: "22px"}}>Le vendredi de 9h à 18h30</P>
          <P style={{fontSize: "22px"}}>Le Samedi de 9h à 18h</P>
          <P style={{fontStyle: "italic"}}>Fermé le lundi et dimanche</P>
          <br/>
          <P style={{fontSize: "22px"}}>Téléphone: 081 61 46 10</P>
        </div>
      </div>
    </Section>

    <Section className="container">
      <div className="row">
        <div className="col-md-4">
          <H2>Vins</H2>
          <P>Nous proposons une gamme étendue de Spumante, Prosecco, Vins, Grappa et spiritueux de toutes les régions de la botte italienne.</P>
          <img src={imageVins} className="img-fluid rounded" style={{minHeight: "270px"}}/>
        </div>
        <div className="col-md-4">
          <H2>Charcuteries</H2>
          <P>San Daniele, mortadella, coppa parma…Notre assortiment de charcuteries ravira vos papilles gustatives.</P>
          <br/>
          <img src={imageCharcuterie} className="img-fluid rounded" style={{minHeight: "270px"}}/>
        </div>
        <div className="col-md-4">
          <H2>Fromages</H2>
          <P>Parmesan Reggiano, mozzarella di bufala, gorgonzola… Venez découvrir un large choix de fromages.</P>
          <br/>
          <img src={imageFromages} className="img-fluid rounded" style={{minHeight: "270px"}}/>
        </div>
      </div>
    </Section>

    <Section className="container">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <H2 className="text-center">Notre histoire</H2>
          <P>Notre maison mère se situe à Genk depuis 1968 et c’est en avril 2011 que Raineri Gembloux a ouvert ses portes. Vous pourrez y découvrir un assortiment des meilleurs produits directement importés d'Italie.</P>
          <P>Épicerie fine, vins, spiritueux, charcuteries, fromages et notre espace traiteur font la richesse de la gastronomie italienne. Nous vous invitons à venir les découvrir dans notre magasin.</P>
        </div>
      </div>
    </Section>

    <Section className="container-fluid">
      <div className="row">
        <div className="col-md-3 col-sm-2"><img src={imageBiscuits}/></div>
        <div className="col-md-3 col-sm-2"><img src={imageFromages2}/></div>
        <div className="col-md-3 col-sm-2"><img src={imageLimoncello}/></div>
        <div className="col-md-3 col-sm-2"><img src={imageRayonFromages}/></div>
        <div className="col-md-3 col-sm-2"><img src={imagerayonHuiles}/></div>
        <div className="col-md-3 col-sm-2"><img src={imageRayonVins}/></div>
        <div className="col-md-3 col-sm-2"><img src={imageRayon}/></div>
        <div className="col-md-3 col-sm-2"><img src={imageFromage}/></div>
      </div>
    </Section>

    <Footer/>
  </div>

export default Page
